import React from "react"
import styled from "styled-components"
import { Text } from "@atoms"

const QuoteBlockGreyBackground = ({ quoteData, ...props }) => {
  const { quote, author } = quoteData
  return (
    <CompWrapper {...props} data-comp={QuoteBlockGreyBackground.displayName}>
      <Text style={{ fontSize: "14px", lineHeight: "21px" }}>
        "{quote?.quote && quote.quote}"
      </Text>
      {author && (
        <AuthorDetailsFlex>
          {author?.headshot?.file?.url && (
            <img
              alt = "author headshot"
              style={{
                width: "34px",
                height: "34px",
                objectFit: "cover",
                borderRadius: "3px",
              }}
              src={author?.headshot?.file?.url}
            />
          )}
          {author.fullName && (
            <Text
              style={{
                marginTop: "1px",
                marginLeft: "8px",
                fontSize: "13px",
                display: "inline",
                lineHeight: "20px",
              }}
            >
              <span style={{ fontWeight: 700 }}>{author?.fullName}</span>
              <span style={{ fontWeight: 400 }}>
                {author?.role && `, ${author?.role}`}
                {author?.company?.name && `, ${author?.company?.name}`}
              </span>
            </Text>
          )}
        </AuthorDetailsFlex>
      )}
    </CompWrapper>
  )
}

QuoteBlockGreyBackground.displayName = "QuoteBlockGreyBackground"
export default QuoteBlockGreyBackground

const CompWrapper = styled.div`
  padding: 20px;
  background: #f2f4f7;
  width: 100%;
  border-radius: 8px;
`

const AuthorDetailsFlex = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`
